/* eslint-disable */
import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import "./newsp.less";

const NewsItem = (props) => {
  let item = props.item;
  let index = props.index;
  let imgUrl = (item.node.featured_media && item.node.featured_media.source_url) || "";
  return (
    <div className={index % 3 === 0 ? "itemFirstByRow" : "itemBox"}>
      <div><img className='image' src={imgUrl} alt='' /></div>
      <h3 className='newsTitle'><div dangerouslySetInnerHTML={{ __html: item.node.title }}/></h3>
      <p className='newsExcerpt'>
        <div dangerouslySetInnerHTML={{ __html: item.node.excerpt }}/>
      </p>
      <div className='itemFooter'>
        <label>{item.node.modified}</label>
        <a className='detail'></a>
      </div>
    </div>
  );
};

const Newsp = (props) => {
  const data = props.data.allWordpressPost.edges;

  return (
    <Layout>
      <div className='outBox'>
        <div className='box'>
          {
            data.map((item, index) => {
              return (
                <a href={item.node.link} target='_blank'>
                  <NewsItem item={item} index={index}/>
                </a>
              );
            })
          }
        </div>
      </div>
    </Layout>
  );
};

export const newsQueryP = graphql`
    query newsQueryP {
        allWordpressPost(sort: {fields: [modified], order: DESC}) {
    edges {
        node {
            id
            title
            slug
            modified( formatString: "YYYY.MM.DD" )
            link
		content
		excerpt
		featured_media {
        source_url
      }

        }
    }
}
}
`;
export default Newsp;
